import axios, { AxiosResponse } from 'axios'
export const API_URL_BASE = process.env.NEXT_PUBLIC_API_URL_BASE || "http://localhost:8000";

export interface GetParams {
  id: string
}

export interface GetAllParams {
  ids?: Array<string>
}

export const post = async (path: string, data?: object, headers: object = {}): Promise<any> => {
  const url = `${API_URL_BASE}${path}`;
  let response: AxiosResponse;
  try {
    response = await axios.post(url, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Max-Age': '3600',
        ...headers,        
      }
    })
  } catch (err: any) {
    console.error(err);
    console.error(err.response?.data?.detail)
    throw({
      errors: [
        err.response?.data?.detail
      ]
    })
  }
  if (response.status === 200) {
    return response.data as object;
  } else {
    console.error(response)
    throw(response.statusText);
  }
}

export const get = async (path: string, headers: object = {}): Promise<any> => {
  const url = `${API_URL_BASE}${path}`;
  const response = await axios.get(url, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Max-Age': '3600',
      ...headers
    }
  })
  if (response.status === 500) {
    throw(response.statusText);
  }
  return response.data as object;
}
export interface SuccessResult {
  success: boolean
}

export const toFormData = (data?: object): FormData => {
  const formData = new FormData();
  if (data) {
    Object.keys(data).map((key, idx) => {
      formData.append(key, Object.values(data)[idx]);
    });
  }
  return formData;
}

export const asDownloadFile = async (data: any, asFileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", asFileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}
