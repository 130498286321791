import { ReactNode } from "react";
import MUIChip from '@mui/material/Chip';

interface ChipParams {
  children?: ReactNode
  [x: string]: any
}


export default function Chip({
  children,
  ...props
}: ChipParams) {
  const color = props.color || "primary";
    return <MUIChip 
      color={color}
      size="small"
      label={children}
      {...props}
    />
}