import FormControlLabel from "@mui/material/FormControlLabel";
import { default as MUIRadio } from "@mui/material/Radio";
import WithFieldErrors from "element/form/WithFieldErrors";
import { ChangeEvent, MouseEvent, MouseEventHandler } from "react";
import { FormFieldProps } from "types";

//import withFieldErrors from 'elements/form/withFieldErrors';

interface RadioProps extends FormFieldProps {
  
}

const Radio = ({
  children,
  value,
  selectedValue,
  onChange,
  formContext,
  labelAlign,
  ...props
}: RadioProps) => {

  // TODO handle form click removal (doesn't fire onChange when re-clicking same item)
  // need to handle onClick instead?
  
  let handleChange;
  if (onChange) {
    handleChange = onChange;
  } else if (formContext && props.name) {
    handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const targetValue = event.target.value;
      const newValue = targetValue === selectedValue ? null : targetValue;      
      formContext.updateField(props.name as string, newValue)
    }
  }

  return <FormControlLabel
    value={value}
    control={
      <MUIRadio 
        checked={selectedValue === value}
        value={value}
        onChange={handleChange}
        color="primary"
        
        {...props} 
      />
    }
    sx={{
      alignItems: labelAlign || 'center'
    }}
    label={children}
  />
}

//export default withFieldErrors(Radio);
export default WithFieldErrors(Radio);
