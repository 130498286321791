import { DataObject } from "./data-object";

export enum TrackerEventType {
  email_sent= "email_sent", 
  email_open= "email_open", 
  email_click= "email_click", 
  email_redirect= "email_redirect", 
  page_view= "page_view"
}

export enum PageType {
  checkout="checkout",
  invoice="invoice",
  client_invoice="client_invoice",
}

export type EmailTracker = DataObject & {
  comms_email_log_id: string
  event_type: TrackerEventType
  event_at: Date
  redirect_url: string
  redirect_tracker_email_id: string
}

export type PageTracker = DataObject & {
  page_type: PageType
  obj_id:	string
  page_url:	string
  event_type:	TrackerEventType
  event_at: Date
}