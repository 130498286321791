// import React, { useContext } from 'react';
// import LocaleContext from 'context/LocaleContext';
// import withTranslatedLabel from 'elements/form/withTranslatedLabel';
import Radio from './Radio';
import { FormLabel } from '@mui/material';
import { SelectOption, SelectProps } from './Select';
import React, { ChangeEvent, ReactNode } from 'react';
import WithFieldErrors from 'element/form/WithFieldErrors';
import { PortableWifiOff } from '@mui/icons-material';
import { InputProps } from './Input';

interface RadioSelectOption {
  value?: any
  disabled?: boolean
  label: string | ReactNode
}

interface RadioGroupProps extends InputProps {
  options: Array<RadioSelectOption>
}

const RadioGroup = ({
  options, 
  name,
  label,
  formContext,
  fieldsKey,
  onChange,
  vertical=false,
  getLabelForOption=null,
  postOnChange,
  labelAlign,
  ...props}: RadioGroupProps) => {


  let controlledValue = props.value;
  if (controlledValue == null && formContext?.data && name) {
    controlledValue = formContext.data[name]
  }
  if (typeof(controlledValue)==='undefined') {
    controlledValue = null;
  }

  let handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  if (onChange) {
    handleChange = onChange;
  } else if (formContext && name) {
    handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      // make sure to cast the value if needed
      let val: any = event.target.value;
      
      const optValues = options.map(o=>o.value);
      if (optValues.includes(true) && val === "true") {
        val = true;
      } else if (optValues.includes(true) && val === "false") {
        val = false;
      } else {
        // numbers
        if (optValues.filter((opt) => typeof(opt)==="number").length) {
          val = parseInt(val);
        }
      }
      // Allow unchecking
      if (val === controlledValue) {
        val = null;
      }
      const newData = formContext.updateField(name, val)
      if (postOnChange) {
        postOnChange(formContext, newData)
      }
    }
  }

  const getLabelForOptionFn = getLabelForOption || ((optionValue: any) => {
    return optionValue.label || optionValue.value;
  })


  // TODO use better layout mechanism than BR

  return <>
    <FormLabel>{label}</FormLabel><br/>
    {options.map((option)=>{
      const label = getLabelForOptionFn(option);
      return <React.Fragment key={option.value}><Radio name={name} value={option.value} 
        disabled={props.disabled || option.disabled}
        selectedValue={controlledValue}         
        onChange={handleChange}
        labelAlign={labelAlign}>
        {label}
      </Radio>{vertical && <br/>}</React.Fragment> 
    })}
  </>
}

export default WithFieldErrors(RadioGroup);

//export default withTranslatedLabel(withFieldErrors(RadioSelect));
