import { PaymentInstrument } from './payment-instrument';
import { Cart } from './cart';
import { Client } from './client';
import { DataObject } from "./data-object"

export enum PaymentMethod {
  ach="ach", 
  card="card",
  check="check",
  wire="wire"
}


export enum PaymentOption {
  pif="pif", 
  installments="installments"
}

export enum ManualPaymentInstrumentType {
  check = "check",
  wire = "wire",
  cash = "cash",
  other = "other",
}

export type PaymentPolicyAllocation = {
    policy_id: string
    amount: number
}


export type  PaymentInvoiceAllocation = {
    invoice_id: string
    amount?: number
    policy_allocations?: Array<PaymentPolicyAllocation>
}


export type FinixTokenizationConstants = {
  environment: string
  application_id: string
}

export type PaymentData = {
  payment_instrument_type: ManualPaymentInstrumentType
  description?: string
  payment_instrument_identifier: string
}

export type PaymentAllocation = DataObject & {
  payment_id: string
  invoice_id?:	string
  credit_id?: string
  insurance_policy_id?: string
  amount:	number
}

export type Payment = DataObject & {
  amount: number
  cart_id: string
  cart?: Cart
  client_id: string
  client?: Client
  agency_client_id?: string
  insured_id?: string

  payment_date: string
  payment_instrument_id: string
  payment_instrument?: PaymentInstrument
  currency: string
  status: string
  finix_transfer_id: string
  
  manual_payment_type?: ManualPaymentInstrumentType
  manual_payment_identifier?: string
  notes?: string

  bank_transaction_id?: string

  is_reversal: boolean
  reversed_payment_id: string
  reversal_type: string

  reversals?: Array<Payment>
  payment_allocations: Array<PaymentAllocation>
  unallocated_amt?: number
}




export type PaymentRefund = {
  payment_id: string,
  refund_amt: number
}