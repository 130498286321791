import { StructuredDate } from "./date"

export enum MerchantOnboardingStep {
  business_profile = 'business_profile',
  business_information = 'business_information',
  principals = 'principals',
  additional_underwriting = 'additional_underwriting',
  bank_account = 'bank_account',
  accept_terms_and_conditions = 'accept_terms_and_conditions',
}

export enum MerchantOnboardingBusinessType {
  INDIVIDUAL_SOLE_PROPRIETORSHIP="INDIVIDUAL_SOLE_PROPRIETORSHIP", 
  CORPORATION="CORPORATION", 
  LIMITED_LIABILITY_COMPANY="LIMITED_LIABILITY_COMPANY", 
  PARTNERSHIP="PARTNERSHIP", 
  ASSOCIATION_ESTATE_TRUST="ASSOCIATION_ESTATE_TRUST", 
  TAX_EXEMPT_ORGANIZATION="TAX_EXEMPT_ORGANIZATION", 
  INTERNATIONAL_ORGANIZATION="INTERNATIONAL_ORGANIZATION", 
  GOVERNMENT_AGENCY="GOVERNMENT_AGENCY"
}

export enum MerchantOnboardingOwnershipType {
  PUBLIC="PUBLIC", 
  PRIVATE="PRIVATE"
}

export type MerchantOnboardingBusinessAddress = {
  city:	string
  country:	string
  region:	string
  line2:string
  line1:	string
  postal_code:	string
}

export type MerchantOnboardingBusinessProfile = {
  business_name: string
  doing_business_as: string
  url: string
  business_phone: string
  business_address: MerchantOnboardingBusinessAddress
}


export type MerchantOnboardingBusinessInformation = {
  business_type: MerchantOnboardingBusinessType
  ownership_type: MerchantOnboardingOwnershipType
  incorporation_date: StructuredDate | Date
  
  business_tax_id:	string
  amex_mid: number
  discover_mid: number
  has_accepted_credit_cards_previously:boolean
  annual_card_volume:	number
  default_statement_descriptor:	string
  max_transaction_amount:	number
  mcc:	string
}

export type AssociatedIdentitiesEntity = {
  first_name:	string
  last_name:	string
  title:	string
  principal_percentage_ownership:	number
  phone:	string
  email:	string
  tax_id:	string
  dob: StructuredDate | Date
  personal_address: MerchantOnboardingBusinessAddress
}


export type MerchantOnboardingPrincipals = {
  principal_1:	AssociatedIdentitiesEntity,
  principal_2:	AssociatedIdentitiesEntity,
  principal_3:	AssociatedIdentitiesEntity,
  principal_4:	AssociatedIdentitiesEntity
}

export enum MerchantOnboardingRefundPolicy {
  NO_REFUNDS = "NO_REFUNDS", 
  MERCHANDISE_EXCHANGE_ONLY = "MERCHANDISE_EXCHANGE_ONLY", 
  WITHIN_30_DAYS = "WITHIN_30_DAYS", 
  OTHER = "OTHER"
}

export type MerchantOnboardingCardVolumeDistribution = {
  card_present_percentage: number
  mail_order_telephone_order_percentage: number
  ecommerce_percentage: number
}

export type MerchantOnboardingVolumeDistributionByBusiness = {
  person_to_person_volume_percentage: number
  other_volume_percentage: number
  business_to_consumer_volume_percentage: number
  business_to_business_volume_percentage: number
  consumer_to_consumer_volume_percentage: number
}

export type MerchantOnboardingAdditionalUnderwriting = {
  average_card_transfer_amount:	number
  average_ach_transfer_amount:	number
  annual_ach_volume:	number
  business_description:	string
  
  refund_policy:	MerchantOnboardingRefundPolicy
  
  card_volume_distribution:	MerchantOnboardingCardVolumeDistribution
  volume_distribution_by_business_type:	MerchantOnboardingVolumeDistributionByBusiness
   
}

export enum MerchantOnboardBankAccountType {
  CHECKING="CHECKING", 
  SAVINGS="SAVINGS", 
  CORPORATE="CORPORATE", 
  CORP_SAVINGS="CORP_SAVINGS"
}

export type MerchantOnboardingPlaidData = {
  plaid_item_id: string
  plaid_account_id: string
}

export type MerchantOnboardingBankAccount = {
  account_type?: MerchantOnboardBankAccountType
  name?:	string
  country?:	string
  bank_name?: string
  bank_code?:	string
  account_number?:	string
  plaid_data?: MerchantOnboardingPlaidData
}

export type MerchantOnboardingAcceptTermsAndConditionsAccept = {
  merchant_agreement_accepted:	boolean
  credit_check_allowed:	boolean
  moov_tos_token?: string
}

export type MerchantOnboardingStepData = MerchantOnboardingBusinessProfile | MerchantOnboardingBusinessInformation | 
MerchantOnboardingPrincipals | MerchantOnboardingAdditionalUnderwriting |
MerchantOnboardingBankAccount | MerchantOnboardingAcceptTermsAndConditionsAccept

export enum FinixStatus {
  PROVISIONING = 'PROVISIONING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum MoovAccountStatus {
  UNVERIFIED = 'unverified',
  PENDING = 'pending',
  VERIFIED = 'verified',
  ERRORED = 'errored',
}

export enum MoovBankAccountStatus {
  NEW = 'new',
  VERIFIED = 'verified',
  VERIFICATION_FAILED = 'verificationFailed',
  PENDING = 'pending',
  ERRORED = 'errored',
}

export type MerchantOnboardingStatus = {
  finix_status?: FinixStatus
  moov_account_status?: MoovAccountStatus
  moov_bank_account_status?: MoovBankAccountStatus
}
