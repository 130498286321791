import { DataObject } from "./data-object";

export enum BankAccountType {
  CHECKING="CHECKING",
  SAVINGS="SAVINGS"
}

export enum PaymentInstrumentType {
  PAYMENT_CARD="PAYMENT_CARD", 
  BANK_ACCOUNT="BANK_ACCOUNT",
  BANK_WIRE="BANK_WIRE",
  BANK_CHECK="BANK_CHECK"
}

export type PaymentInstrument = DataObject & {
  payment_instrument_id: string
  insured_id: string
  instrument_type:	PaymentInstrumentType
  card_brand:	string
  card_last4:	string
  bank_type:	string
  bank_masked_account_number:	string
  finix_instrument_id:	string
  store_payment_instrument: boolean
  name: string
}

