import { User } from 'types';
import { get, post, SuccessResult } from './base';

export interface LoginParams {
  username: string
  password: string
}

// TODO specify types for these requests
export interface LoginRequestParams {
  login: LoginParams
  client_id?: string
}

export const login = async (data: LoginRequestParams): Promise<SuccessResult> => {
  return await post("/auth/login", data)
}

export const logout = async () => {
  return await post("/auth/logout")
}

export const identify = async (): Promise<User> => {
  return await get("/user/get/self");
}

