import { Grid } from '@mui/material';
import { ReactNode } from 'react';

interface GridItemProps {
  children?: ReactNode,  
  [x: string]: any
}

export default function GridItem({
  children,
  ...props
}: GridItemProps) {
  return <Grid item {...props}>
    {children}
  </Grid>
}