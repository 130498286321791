import { Client } from "./client";
import { EndInsured } from './end-insured';
import { DataObject } from "./data-object";
import { Policy, Endorsement } from "./policy";
import { PaymentOption, PaymentMethod, Payment, PaymentAllocation } from "./payment";
import { AgencyPartner } from "./agency-partner";
import { Credit } from "./credit";
import { StatementInvoice } from "./statement";

export enum InvoiceStatus {
  draft = "draft",
  sent = "sent",
  canceled = "canceled"

}

export enum BillingType {
  direct_bill="direct_bill", 
  agency_bill="agency_bill"
}

export type InvoiceData = {
  // loan_id?: string
  payment_date?: string
  // payment_amt?: number
  payment_method?: {[x: string]: any}
  // payment_option?: PaymentOption
  // payment_str?: string
  payment_type?: PaymentMethod
  notes?: string
  // transaction_type?: string
}

export type InvoiceItem = DataObject & {
  invoice_id?: string
  invoice?: Invoice
  insurance_policy_id?: string
  policy?: Policy
  insurance_endorsement_id?: string
  endorsement?: Endorsement
  
  premium_id?: string
  fee_id?: string
  tax_id?: string

  amount: number

}


export type Invoice = DataObject & {
  client_id?: string
  client?: Client
  insured_id?: string  
  insured?: EndInsured
  
  agency_client_id?: string
  agency_client?: AgencyPartner
  
  cart_id?: string
  invoice_num?: string

  status?: InvoiceStatus
  billing_type?: BillingType
  is_statement_invoice: boolean
  data: InvoiceData
  invoice_date: string
  due_date: string
  expiration_date: string

  mga_client_id?: string

  invoice_items: Array<InvoiceItem>
  payments?: Array<Payment>
  payment_allocations?: Array<PaymentAllocation> 
  credits?: Array<Credit>
  policies?: Array<Policy>
  endorsements?: Array<Endorsement>
  
  statement_invoices?: Array<StatementInvoice>

  payments_total_amt?: number
  invoice_total_amt?: number
  balance_due_amt?: number
}