import { DataObject } from "./data-object";

export enum PolicyItemCategory {
  premium="premium", 
  tax="tax", 
  fee="fee" 
}

export type PolicyItemType = DataObject & {
  client_id: string
  item_category: PolicyItemCategory
  name: string
  description: string
  
  default_amount: number
  default_description: string
  
  sort: number  
}

export type PolicyItem = DataObject & {
  policy_item_type_id: string
  insurance_policy_id?: string
  insurance_endorsement_id?: string
  amount: number
  description: string
  policy_item_type?:	PolicyItemType
}