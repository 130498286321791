//import { useContext } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import { FormContext } from 'types';

//import LocaleContext from 'context/LocaleContext';
// import { getTranslated } from './withTranslatedLabel';

interface FieldErrorProps {
  name: string,
  formContext: FormContext,
  index?: number,
}

const FieldError = ({ 
  name, 
  formContext,
  index,
}: FieldErrorProps) => {

  // const localeContext = useContext(LocaleContext);
  // const { t } = localeContext;
  // const fk = formContext?.fieldsKey;
          
  if (!formContext) {
    return <></>;
  }
  const { serverErrors, errors, validationErrors } = formContext;

  const fieldErrorMessages = [
    ...(errors.fields[name] || []),
    ...(validationErrors.fields[name] || []),
    // server errors may require lookup by name and index
    ...(((index === undefined) ? serverErrors.fields[name] : ((serverErrors.fields[name] && serverErrors.fields[index]))) || [])
  ];
  
  return <>{fieldErrorMessages.map((msg)=>{
    // const translatedErrorMessage = t(`fields.${fk ? `${fk}.` : ''}${name}.errors.${msg}`, 
    //   {fallbackKey: `fields.errors.${msg}`, 
    //   default: msg});
    return <FormHelperText error key={msg}>
      {msg}
    </FormHelperText> 
  })}</>
}

export default FieldError