import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import { Button, GridContainer, GridItem } from 'element';
import { ReactChildren } from 'react';
import { FormMode, FormContext, FormContextData, Maybe } from 'types';

import { Step, StepLabel, Stepper } from '@mui/material';
import { FormErrors } from 'types/formErrors';

const PREFIX = 'Form';

const classes = {
  formErrors: `${PREFIX}-formErrors`,
  actions: `${PREFIX}-actions`
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.formErrors}`]: {
    color: theme.palette.error.main,
    listStyle: 'none',
    padding: 0,
  },
  [`& .${classes.actions}`]: {
    paddingTop: theme.spacing(2)
  }
}));



export const defaultFormErrors = () => ({
  form: [],
  fields: {}
})

export const defaultFormContext: FormContext = {
  data: {},
  errors: {...defaultFormErrors()},
  serverErrors: {...defaultFormErrors()},
  validationErrors: {...defaultFormErrors()},
  clearErrors: () => {},
  updateData: () => {},
  updateField: () => { return {} },
  disabled: false,
  disable: () => {},
  enable: () => {}
}

interface FormProps<DataType=FormContextData> {
  name?: string,
  formContext?: FormContext<DataType>,
  onChange?(data: FormContextData): void,
  onSubmit?(data: object, context?: {
    formContext: FormContext
  }): void
  onCancel?(formContext?: FormContext): void
  onReset?(formContext?: FormContext): void
  errors?: FormErrors
  steps?: Array<Array<string> | string>
  initialData?: object | null
  initiallyDisabled?: boolean
  externallyDisabled?: boolean
  submitLabel?: string
  resetLabel?: string
  cancelLabel?: string
  inline?: boolean
  buttonSize?: string
  buttonProps?: {[x: string]: any}
  fieldsKey?: string
  mode?: FormMode
  children?(formContext: FormContext): React.ReactNode

  requiredFields?: Array<string>
}



export default function Form<DataType=FormContextData>({
  name,
  formContext: parentFormContext,
  onChange,
  onSubmit,
  onCancel,
  onReset,
  errors = defaultFormErrors(),  
  steps,
  initialData = {},
  initiallyDisabled = false,
  externallyDisabled = false,
  submitLabel,
  resetLabel="Reset",
  cancelLabel="Cancel",
  inline,
  buttonSize,
  buttonProps,
  fieldsKey,
  requiredFields,
  mode,
  children,
}: FormProps<DataType>) {

  const [data, setData] = useState<object | null>(initialData)
  const [validationErrors, setValidationErrors] = useState<FormErrors>(defaultFormErrors())

  const setDataWithParentContext = (newData: object | null) => {
    setData(newData);
    if (parentFormContext && name) {
      parentFormContext.updateField(name, newData)
    }
    if (onChange) {
      onChange(newData)
    }
  }

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [disabled, setDisabled] = useState(initiallyDisabled)
  const [serverErrors, setServerErrors] = useState<FormErrors>(defaultFormErrors());
  
  const computedButtonProps = {...(buttonProps || {})}
  if (buttonSize) {
    computedButtonProps[buttonSize] = true;
  }

  const validate = (): boolean => {
    if (requiredFields) {
      const newFieldErrors: {[x: string]: Array<string>} = {};
      requiredFields.forEach((fieldName)=>{
        if (formContext?.data && (formContext?.data[fieldName] == null || formContext?.data[fieldName] == "")) {
          newFieldErrors[fieldName] = ["Required"]
        }
      })
      const newErrors = {...validationErrors};
      newErrors.fields = newFieldErrors;
      setValidationErrors(newErrors);
      
      if (Object.values(newFieldErrors).length > 0) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }


  const submitForm = async () => {
    if (onSubmit) {
      setDisabled(true);
      if (!validate()) {
        setDisabled(false);
        return;
      }
      try {
        if (data) {
          await onSubmit(data, {formContext});
          // advance steps
          if (steps && steps.length && (currentStep + 1) < steps.length) {
            setCurrentStep(currentStep + 1);
          }
        }
        setDisabled(false);  
      } catch (err: any) {
        const newServerErrors: FormErrors = {form: [], fields: {}};
        if (err.errors && err.errors.length) {
          err.errors.forEach((errItem: any)=>{
            if (typeof(errItem)==="string") {
              newServerErrors.form.push(errItem);
            } else if (Array.isArray(errItem)) {
              errItem.forEach((errDetail: {loc: Array<string>, msg: string, type: string} | string) => {
                if (typeof(errDetail)==="string") {
                  newServerErrors.form.push(errDetail);
                } else {
                  const locations = [errDetail.loc].flat();
                  const { msg } = errDetail;
                  locations.forEach(field=>{
                    // TODO: this field can either be a string or number, where the number represents the index
                    // of the same-named item in a nested field. Refactor this multi-typed fields array.
                    newServerErrors.fields[field] ||= [];
                    newServerErrors.fields[field].push(msg);  
                  })
                }
              })
            } else {
              newServerErrors.form.push("Unknown server error");
              console.error(errItem);
            }
          });
          setServerErrors(newServerErrors);
        }
        setDisabled(false);
      }
    }
  }

  const formContext: FormContext = {
    data,
    errors,
    serverErrors: parentFormContext?.serverErrors || serverErrors,
    validationErrors,
    disabled,
    fieldsKey,
    currentStep,
    setCurrentStep,
    steps,
    clearErrors: () => {
      setServerErrors(defaultFormErrors())
    },
    disable: () => {
      setDisabled(true);
    },
    enable: () => {
      setDisabled(false);
    },
    updateData: (newData: object) => {
      setDataWithParentContext(newData);
    },
    updateField: (name: string, value: any) => {
      const newData: {[key: string]: any} = {...data};
      newData[name] = value;
      
      setDataWithParentContext(newData);
      return newData;
    },
    submitForm
  }


  const defaultSubmitLabel = submitLabel || (steps?.length && currentStep + 1 < steps.length ? "Next" : "Submit");

  const isDisabled = externallyDisabled || disabled

  const submitButton = onSubmit && <Button disabled={isDisabled} onClick={submitForm} {...computedButtonProps}>{defaultSubmitLabel}</Button>
  const resetButton = onReset && <Button disabled={isDisabled} onClick={() => onReset(formContext)} outlined  {...computedButtonProps}>{resetLabel}</Button>
  const cancelButton = onCancel && <Button disabled={isDisabled} onClick={() => onCancel(formContext)} outlined  {...computedButtonProps}>{cancelLabel}</Button>
  
  let actions = null;
  if (submitButton) {
    if (steps && steps.length && currentStep > 0) {
      actions = <>
        <GridItem xs={12} sm="auto"><Button disabled={isDisabled} onClick={() => setCurrentStep(currentStep - 1)} outlined  {...computedButtonProps}>Back</Button></GridItem>
        <GridItem xs={12} sm="auto">{submitButton}</GridItem>
      </>
    } else if (cancelButton) {
      if (resetButton) {
        actions = <>
          <GridItem xs={12} sm="auto">{submitButton}</GridItem>
          <GridItem xs={12} sm="auto">{resetButton}</GridItem>
          <GridItem xs={12} sm="auto">{cancelButton}</GridItem>
        </>
      } else {
        actions =<>
          <GridItem xs={12} sm="auto">{submitButton}</GridItem>
          <GridItem xs={12} sm="auto">{cancelButton}</GridItem>
        </>
      }
    } else if (resetButton) {
      actions =<>
        <GridItem xs>{submitButton}</GridItem>
        <GridItem xs>{resetButton}</GridItem>
      </>
    } else {
      actions = <GridItem xs={12} sm="auto">{submitButton}</GridItem>
    }
  }  

  const renderedChildren = <>
    {children && children(formContext)}    
    {!!inline && <GridItem flexGrow={1}>{actions}</GridItem>}
  </>

  let stepper = null;
  if (steps) {
    stepper = <GridItem xs={12}><Stepper activeStep={currentStep}>
      {steps.map((labelOrLabels, index) => {
        const label = Array.isArray(labelOrLabels) ? labelOrLabels[1] : labelOrLabels
        return <Step key={label} completed={currentStep > index } onClick={()=>setCurrentStep(index)}>
          <StepLabel onClick={()=>setCurrentStep(index)}>{label}</StepLabel>
        </Step>
      })}
    </Stepper></GridItem>
  }

  const mergedFormErrors = [
    ...(serverErrors.form || []),
    ...(errors.form || []),
    ...(validationErrors.form || [])
  ]


  return (
    <Root>
      <GridContainer direction="column">
        {stepper}
        <GridItem xs={12}>
          { inline ? 
            <GridContainer spacing={2} alignItems={"center"}>{renderedChildren}</GridContainer>
            :
            renderedChildren
          }
        </GridItem>  
        { mergedFormErrors.length > 0 && 
          <GridItem xs={12}>
            <ul className={classes.formErrors}>
              {mergedFormErrors.map(e=><li key={e}>{e}</li>)}
            </ul>
          </GridItem>
        }
        {!inline && <GridItem xs={12}>
          <GridContainer spacing={2} className={classes.actions} justifyContent="center">
            { actions }
          </GridContainer>
        </GridItem>}
      </GridContainer>
    </Root>
  );
}
