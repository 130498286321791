import { Dialog as MUIDialog, DialogContent, DialogTitle, IconButton, Breakpoint } from "@mui/material";
import { ReactNode } from "react";
import { styled } from '@mui/material/styles';
import CloseIcon from 'icon/close-icon';
import { SxProps } from "@mui/system";

const StyledDialog =styled(MUIDialog)(({theme}) => `
  .MuiDialogContent-root {
    padding: 25px 35px;
  }
`);

interface DialogProps {
  maxWidth?: false | Breakpoint
  title?: string
  fullWidth?: boolean
  fullScreen?: boolean
  fullHeight?: boolean
  open: boolean
  onClose?: () => void
  children: ReactNode
}

export default function Dialog ({
  title,
  open,
  onClose,
  children,
  maxWidth = false,
  fullWidth,
  fullScreen,
  fullHeight
}: DialogProps) {
  let paperSx: SxProps = {};
  if (fullHeight) {
    paperSx = {height: '100%'}
  }
  return <StyledDialog PaperProps={{sx: paperSx}} fullWidth={fullWidth} fullScreen={fullScreen} maxWidth={maxWidth} open={open} onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown")=>onClose && onClose()}>
    {(title || onClose) && <DialogTitle sx={{textAlign: "center", position: "relative"}}>
      {title}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={()=>{onClose()}}
          sx={{
            position: 'absolute',
            right: "23px",
            top: "13px",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>}
    <DialogContent>
      <br/>
      {children}
    </DialogContent>
  </StyledDialog>
}