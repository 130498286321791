import { DataObject } from "./data-object";
import { Transfer } from './transfer';

export enum SettlementStatus {
  APPROVED="APPROVED",
  AWAITING_APPROVAL="AWAITING_APPROVAL"
}

export type Settlement = DataObject & {
  total_fees: 	number
  destination: string
  merchant_id: 	string
  type: 	string
  processor: 	string
  payment_type: string
  application: 	string
  total_amount: 	number
  identity: 	string
  total_fee: 	number
  currency: 	string
  net_amount: 	number
  funds_flow: string
  

  //tags: Tags{...}
  
  status: SettlementStatus
  transfers: Array<Transfer>
 

}