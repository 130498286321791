import {default as MUIBreadcrumbs} from '@mui/material/Breadcrumbs';
import { Body, Link } from 'element';


export interface BreadCrumbItem {
  label: string,
  url: string
}

interface BreadCrumbsProps {
  items: Array<BreadCrumbItem>
  currentPageLabel: string
}

export default function BreadCrumbs({
  items,
  currentPageLabel
}: BreadCrumbsProps) {
  return <MUIBreadcrumbs aria-label="breadcrumb">
    {items.map((item)=>{
      return <Link href={item.url}>{item.label}</Link>
    })}
    <Body>{currentPageLabel}</Body>
  </MUIBreadcrumbs>
}