import { DataObject } from "./data-object";

// export enum AccountSubtype = {
//   401a, 401k, 403B, 457b, 529, brokerage, cash isa, education savings account, gic, health reimbursement arrangement, hsa, isa, ira, lif, lira, lrif, lrsp, non-taxable brokerage account, other, prif, rdsp, resp, rlif, rrif, pension, profit sharing plan, retirement, roth, roth 401k, rrsp, sep ira, simple ira, sipp, stock plan, thrift savings plan, tfsa, trust, ugma, utma, variable annuity, credit card, paypal, cd, checking, savings, money market, prepaid, auto, commercial, construction, consumer, home, home equity, loan, mortgage, overdraft, line of credit, student, cash management, keogh, mutual fund, recurring, rewards, safe deposit, sarsep
// }

export type BankAccount = DataObject & {
  client_id:	string
  insured_id:	string
  plaid_item_id:	string
  plaid_account_id:	string
  name:	string
  institution_name: string
  official_name: string
  mask:	string
  account_number:	string
  routing_number:	string
  is_unused:	boolean

  account_type:	string
  
  balance_current: number
  balance_available: number
  
  // AccountSubtypeEnumstring
  // Array: [ 65 ]
  
  
  //tags:	Tags[...]
  //data:	Data{...}
  
}

export enum TransactionCode {
  adjustment = "adjustment", 
  atm = "atm", 
  bank_charge = "bank charge", 
  bill_payment = "bill payment", 
  cash = "cash", 
  cashback = "cashback", 
  cheque = "cheque", 
  direct_debit = "direct debit", 
  interest = "interest", 
  purchase = "purchase", 
  standing_order = "standing order", 
  transfer = "transfer" 
}

export type BankTransaction = DataObject & {
  bank_account_id:	string
  plaid_account_id:	string
  plaid_transaction_id:	string
  plaid_pending_transaction_id:	string
  is_pending:	boolean
  amount:	number
  date:	Date
  name:	string
  unofficial_currency_code:	string
  iso_currency_code:	string
  reference_number:	string
  ppd_id:	string
  payee:	string
  by_order_of:	string
  payer:	string
  payment_processor:	string
  reason:	string
  check_number:	string
  is_unused:	boolean
  is_applied:	boolean
  applied_amount: number
  transaction_code:	TransactionCode
  
  // tags	Tags[...]
  // data	Data{...}
}