import { Client } from "types"
import { DataObject } from "./data-object"

export enum UserType {
  admin = "admin",
  client = "client",
  insured = "insured",
}

export type User = DataObject & {
  first_name: string
  last_name: string
  email: string

  is_active: boolean
  
  user_type: UserType
  client_id: string
  client?: Client
}