import React, { useState } from 'react';
import Chart, { ChartProps } from './Chart';

import { formatDollars } from 'dsl/money';

import theme, { BUTTON_RADIUS } from 'theme';
//import { Grid, Paper, Typography } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import { getDataItemColor } from './chartColors';
import GridContainer from 'element/GridContainer';
import GridItem from 'element/GridItem';
import { Overline } from 'element/typography';


interface LineChartProps extends ChartProps {
}


export default function LineChart({
  name,
  chartData
}: LineChartProps) {

  const chart = <Chart 
    chartType='line'
    chartData={chartData}
  />

  return <GridContainer column>
    <GridItem>
      <Overline>{name}</Overline>                
    </GridItem>
    <GridItem>
      {chart}
    </GridItem>
  </GridContainer>

}
