import { ReactNode, useState } from "react";
import { Dialog, GridContainer, Button } from 'element';
import GridItem from "./GridItem";

interface ConfirmableProps {
  message: string
  onConfirm(): void
  children(handleConfirm: ()=>void): ReactNode
}


export default function Confirmable({
  message,
  onConfirm,
  children
}: ConfirmableProps) {
  const [open, setOpen] = useState<boolean>(false);
  const handleConfirm = () => {
    setOpen(true);    
  }

  return <>
    {children(handleConfirm)}
    <Dialog title="Please Confirm" open={open} onClose={()=>setOpen(false)}>
      <GridContainer column>
        <GridItem>
          {message}      
        </GridItem>
        <GridItem>
          <GridContainer justifyContent="space-around">
            <GridItem>
              <Button onClick={()=>{
                if (onConfirm) {
                  onConfirm()
                }
                setOpen(false);                
              }}>Yes</Button>
            </GridItem>
            <GridItem>
              <Button onClick={()=>{
                setOpen(false);                
              }}>No</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      
    </Dialog>
  </>
}