import { PaletteOptions } from '@mui/material';
import {
  purple,
  lightBlue,
  green,
  blue,
  orange,
  red,
} from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    appBarBackground: Palette['primary'];
    primaryLight: Palette['primary'];
    secondaryLight: Palette['primary'];
  }
  interface PaletteOptions {
    appBarBackground: PaletteOptions['primary'];
    primaryLight: PaletteOptions['primary'];
    secondaryLight: PaletteOptions['primary'];
  }
}




export const gradients = {
  dark: "linear-gradient(101deg, rgba(35, 11, 49, 0.8) 17.56%, rgba(23, 12, 61, 0.8) 52.39%, rgba(23, 11, 49, 0.8) 83.77%), #FFFFFF",
  default: "linear-gradient(-15deg, #886BD0 10%, #C47DD0 35%, #FFFFFF 100%)",
  light: "linear-gradient(320deg, #34BFFF 12.84%, #C6EDFF 85.72%)",
}

export const colors = {
  primary: purple,
  secondary: lightBlue,
  info: blue,
  warning: orange,
  success: green,
  error: red
}

export const chartColors = [
  purple[500],
  lightBlue[500],
  green[500],
  //orange[500],
  
  purple[200],
  lightBlue[200],
  green[200],
  //orange[200],
  
  purple[50],
  lightBlue[50],
  green[50],
  //orange[50],
  
]


const palette: PaletteOptions = {
  primary: purple,
  primaryLight: {
    main: purple[100],
    light: purple[50],
    dark: purple[200]
  },
  secondary: lightBlue,
  secondaryLight: {
    main: lightBlue[50]
  },
  success: green,
  info: blue,
  warning: orange,
  error: red,
  appBarBackground: {
    main: '#FFFFFF'
  },
  background: {
    default: '#f5f5f5',
  }
}

export default palette;