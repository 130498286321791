import FormControlLabel from "@mui/material/FormControlLabel";
import { ChangeEvent } from "react";
import { CheckboxProps } from "./Checkbox";
import Switch from '@mui/material/Switch';


// import withFieldErrors from 'elements/form/withFieldErrors';
// import withTranslatedLabel from "elements/form/withTranslatedLabel";

interface ToggleProps extends CheckboxProps {

}

export default function Toggle({
  formContext,
  name,
  onChange,
  label,
  ...remainingProps
}: ToggleProps) {

  const { checked, indeterminate, ...props } = remainingProps;
  let isChecked: boolean = false;
  // Explicit passing of checked or indeterminate props overrides formdata values
  if (checked != null) {
    isChecked = checked
  } else if (indeterminate != null) {
    isChecked = indeterminate;
  } else if (formContext && name) {
    isChecked = formContext.data && formContext.data[name]
  }

  let handleChange = null;
  if (onChange) {
    handleChange = onChange;
  } else if (formContext && name) {
    handleChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      formContext.updateField(name, checked)
    }
  }

  return <FormControlLabel
    control={
      <Switch
        color="primary"
        checked={isChecked}
        onChange={handleChange}
        {...props} 
      />
    }
    label={label}
  />
}

// export default withTranslatedLabel(withFieldErrors(Checkbox));