import { CardActionArea, CardActions, CardContent, CardHeader, CardMedia } from '@mui/material';
import { default as MuiCard } from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { BORDER_RADIUS } from 'theme';
import { colors } from 'theme/palette';
import { Overline } from './typography';

export interface CardProps {
  title?: React.ReactNode
  footer?: React.ReactNode
  center?: boolean
  selectable?: boolean
  selected?: boolean
  fullHeight?: boolean
  children?: React.ReactNode
  mediaLeft?: React.ReactNode
  mediaRight?: React.ReactNode
  mediaTop?: React.ReactNode
  mediaBottom?: React.ReactNode
  disablePadding?: boolean
  [x: string]: any
}

// TODO extract selectable/other card types to other components

const StyledCard =styled(MuiCard)(({theme}) => `
  box-shaow: 0px 4px 15px -4px rgba(0, 0, 0, 0.08);        
  position: relative;
  & .MuiCardContent-root {
    padding: 25px 35px;
    &:last-child {
      padding: 25px 35px;
    }
  }
  &.content-card-no-padding {
    .MuiCardContent-root {
      padding: 0;
      &:last-child {
        padding: 0;
      }
    }
  }


  & .content-card-media {
    border-radius: ${BORDER_RADIUS}px;
    background-color: ${colors.primary[50]};
  }

  &.centered-card {
    text-align: center;
  }
  &.fullHeight-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &.selectable-card {
    cursor: pointer;
    border-radius: 3px;
    box-shadow: none;
    &:hover {
      background-color: ${colors.secondary[50]}
    }
    &.selected {
      background-color: ${theme.palette.secondary.light}
    }
  }
`)

export default function ContentCard({
  title,
  footer,
  center, 
  fullHeight, 
  selectable, 
  selected, 
  children,
  mediaLeft,
  mediaRight,
  mediaTop,
  mediaBottom,
  disablePadding,
  ...props
}: CardProps) {
  let className = props.className || '';
  if (disablePadding) {
    className += ' content-card-no-padding';
  }
  if (center) {
    className += ' centered-card';
  }
  if (fullHeight) {
    className += ' fullHeight-card';
  }
  if (selectable) {
    className += ' selectable-card';
  }
  if (selected) {
    className += ' selected'
  }

  let mediaContent: ReactNode;
  if (mediaLeft) {
    mediaContent = <CardMedia
      className={'content-card-media'}
    >{mediaLeft}</CardMedia>
  }
  if (mediaTop) {
    mediaContent = <CardMedia
      className={'content-card-media'}
    >{mediaTop}</CardMedia>
  }
  if (mediaRight) {
    mediaContent = <CardMedia
      className={'content-card-media'}
    >{mediaRight}</CardMedia>
  }
  if (mediaBottom) {
    mediaContent = <CardMedia
      className={'content-card-media'}
    >{mediaBottom}</CardMedia>
  }

  return <StyledCard sx={{display: (mediaLeft || mediaRight) ? 'flex' : 'block'}} {...props} className={className}>
    {(mediaLeft || mediaTop) && mediaContent}
    {title && <CardHeader sx={{
      padding: "25px 35px 0"
    }} 
      titleTypographyProps={{
        variant: "h2"
      }}
      title={title} />}
    <CardContent sx={{flexGrow: 1}}>      
      {children}
    </CardContent>
    {(mediaRight || mediaBottom) && mediaContent}
    {footer && <CardActions sx={{
      padding: "0 35px 25px",
      justifyContent: 'flex-end'
    }}>{footer}</CardActions>}
  </StyledCard>
}