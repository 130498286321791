import { ReactNode } from 'react';
import { Grid } from '@mui/material';

interface GridContainerProps {
  spacing?: number,
  column?: boolean,
  children?: ReactNode,
  [x: string]: any
}

export default function GridContainer({
  spacing,
  children,
  column,
  ...props
}: GridContainerProps) {
  const finalProps = {...props}
  if (spacing != null) {
    finalProps.spacing = spacing;
  } else {
    finalProps.columnSpacing = 3;
    finalProps.rowSpacing = 2;
  }
  if (column) {
    finalProps.direction = "column";
  }
  return <Grid container {...finalProps}>
    {children}
  </Grid>
}