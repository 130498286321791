import { ReactNode } from "react";
import GridContainer from "./GridContainer";
import GridItem from "./GridItem";
import { H1 } from "./typography";


interface PageHeaderParams {
  title: ReactNode,
  actions?: Array<ReactNode>
}

export default function PageHeader({
  title,
  actions
}: PageHeaderParams) {
  return <GridContainer justifyContent="space-between">
    <GridItem>
      <H1>{title}</H1>
    </GridItem>
    <GridItem>
      <GridContainer justifyContent="flex-end">
        {(actions || []).map((a,i)=>{
          return <GridItem key={`action-${i}`}>
            {a}
          </GridItem>
        })}
      </GridContainer>
    </GridItem>
  </GridContainer>
}