import { Box, SxProps } from "@mui/system";
import { ReactNode } from "react";
import { styled } from '@mui/material/styles';
import { BUTTON_RADIUS } from "theme";

interface SelectableBoxProps {
  onSelect: ()=> void 
  selected: boolean
  children: ReactNode
  sx?: SxProps
}

const StyledBox = styled(Box)(({theme}) => `
  cursor: pointer;
  height: 100%;
  background: ${theme.palette.grey[50]};
  border-radius: ${BUTTON_RADIUS}px;
  text-align: center;
  padding: ${theme.spacing(2)};
  border: 1px solid ${theme.palette.grey[100]};
  &.selectable-box-selected {
    color: ${theme.palette.primary.main};
    background: ${theme.palette.primaryLight.light};
    border-color: ${theme.palette.primaryLight.main};
  }
`)

export default function SelectableBox({
  onSelect,
  selected,
  children,
  sx 
}: SelectableBoxProps) {
  return <StyledBox 
    sx={sx}
    className={selected ? 'selectable-box-selected' : ''}
    onClick={()=>onSelect()}
  >
    {children}
  </StyledBox>
}