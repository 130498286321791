import {default as NextLink} from 'next/link';
import {default as MUILink} from '@mui/material/Link';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material';


interface LinkProperties {
  href?: string
  newTab?: boolean
  disabled?: boolean
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined
  children?: React.ReactNode
  [x: string]: any
}

export default function Link({
  href,
  disabled,
  color = "primary",
  children,
  newTab,
  ...props
}: LinkProperties) {
  const { onClick } = props;
  const disabledStyles = disabled ? {
    cursor: 'default',
    textDecoration: 'none',
    color: 'text.secondary',
  } : {}
  if (onClick) {

    return <MUILink href="#" sx={disabledStyles} onClick={(e)=>{
      e.preventDefault();
      e.stopPropagation();
      if (!disabled) {
        onClick();
      }
    }}>
      {children}
    </MUILink>
  } else {
    const extraProps: {
      target?: string
      rel?: string
      [x: string]: any
    } = {...props}
    if (newTab) {
      extraProps.target="_blank"
      extraProps.rel="noreferrer noopener"
    }
    const anchor = <MUILink sx={disabledStyles} color={color} {...extraProps}>
      {children}
    </MUILink>
    if (href) {
      return <NextLink href={href} passHref>
        {anchor}
      </NextLink>
    } else {
      return anchor
    }
  }
}

