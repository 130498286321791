import { Alert, AlertColor, Snackbar } from "@mui/material";
import { ReactNode } from "react";

interface ToastProps {
  open: boolean
  children: ReactNode
  severity?: AlertColor
  onClose(): void
}

const DURATION=6000;

export default function Toast({
  open,
  children,
  severity="success",
  onClose
}: ToastProps) {
  return <Snackbar
    open={open}
    onClose={onClose}
    autoHideDuration={DURATION}
    >
      <Alert severity={severity}>{children}</Alert>
    </Snackbar>

}