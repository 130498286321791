import { Tooltip } from "@mui/material";
import { ReactChild, ReactFragment, ReactNode, ReactPortal } from "react";

import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface TooltipIconParms {
  children: boolean | ReactChild | ReactFragment | ReactPortal,
  filled?: boolean
  fontSize?: string
}

export default function TooltipIcon({
  children,
  filled,
  fontSize
}: TooltipIconParms) {
  const Icon = filled ? InfoIcon : InfoOutlinedIcon
  return <Tooltip title={children}>
    <Icon color="primary" sx={{fontSize: fontSize || "14px"}} />
  </Tooltip>
}