import FormControlLabel from "@mui/material/FormControlLabel";
import { default as MUICheckbox } from "@mui/material/Checkbox";
import { FormFieldProps } from "types";
import { ChangeEvent } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import WithFieldErrors from './WithFieldErrors'


export interface CheckboxProps extends FormFieldProps {
  inverted?: boolean
}

const Checkbox = ({
  formContext,
  name,
  onChange,
  label,
  hasError,
  inverted,
  ...remainingProps
}: CheckboxProps) => {

  const { checked, indeterminate, ...props } = remainingProps;
  let isChecked: boolean = false;
  // Explicit passing of checked or indeterminate props overrides formdata values
  if (checked != null) {
    isChecked = checked
  } else if (indeterminate != null) {
    isChecked = indeterminate;
  } else if (formContext && name) {
    isChecked = formContext.data && (inverted ? !formContext.data[name] : formContext.data[name]);
  }

  let handleChange = null;
  if (onChange) {
    handleChange = onChange;
  } else if (formContext && name) {
    handleChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      formContext.updateField(name, inverted ? !checked : checked);
    }
  }

  return <FormControl error={hasError}>
    <FormControlLabel
      control={
        <MUICheckbox 
          checked={isChecked}
          indeterminate={indeterminate}
          onChange={handleChange}
          {...props}
        />
      }
      label={label}
    />
  </FormControl>
}

export default WithFieldErrors(Checkbox);