import { createTheme } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material';
import { default as defaultPalette, colors } from './palette';
import { ClientBranding } from 'types';

export const BORDER_RADIUS=20;
export const INPUT_RADIUS=4;
export const BUTTON_RADIUS=8;

export const buildTheme = (buildPalette:(defaultPalette: PaletteOptions)=>PaletteOptions) => {
  const palette = buildPalette(defaultPalette);
  const baseTheme = createTheme({palette})
  return createTheme({
    palette,
    shape: {
      borderRadius: INPUT_RADIUS,
    },
    typography: {
      fontSize: 14,
      fontFamily: "Poppins, sans-serif",
      h1: {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 400
      },
      h2: {
        fontSize: "20px",
        lineHeight: "26px",
        fontWeight: 400,      },
      h3: {
        fontSize: "16px",
        lineHeight: "28px",
        fontWeight: 400,
      },
      overline: {
        color: baseTheme.palette.text.disabled,
        fontSize: "12px",
        lineHeight: "32px",
        fontWeight: 400,
        textTransform: 'uppercase'
      },
      body1: {
        fontSize: "14px",
        lineHeight: "20px"
      },
      body2: {
        fontSize: "12px",
        lineHeight: "20px",
        color: baseTheme.palette.text.disabled
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.appBarBackground.main,
            boxShadow: 'none',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: baseTheme.palette.divider,
            height: 89
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            margin: 0,
            padding: 0,
            fontFamily: "Poppins, sans-serif",        
            backgroundColor: baseTheme.palette.background.default,
            '@media print': {
              backgroundColor: 'white',
              color: 'black'
            }
          },
          ul: {
            paddingLeft: baseTheme.spacing(3)
          },
          '.MuiTextField-root .MuiInputBase-multiline': {
            padding: 0,
            '.MuiInputBase-input': {
              padding: "16.5px 14px"
            }
            
          }
        },
      },
      // Commented lines below is experimenting with shrink label stylings
      // MuiOutlinedInput: {
      //   styleOverrides: {
      //     notchedOutline: {
      //       'legend span': {
      //         display: 'none'
      //       }
      //     }
      //   }
      // },
      // MuiInputLabel: {
      //   styleOverrides: {
      //     root: {
      //       transform: 'translate(14px, 32px) scale(1)'
      //     },
      //     shrink: {
      //       transform: 'translate('+INPUT_RADIUS+'px, -8px) scale(1)',
            
      //     }
      //   }
      // },
      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //       padding: baseTheme.spacing(2) + ' 0 0 0',
            
      //     }
      //   }
      // },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: baseTheme.palette.background.paper
          }
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            paddingBottom: '2px'
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            color: baseTheme.palette.text.primary
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          // filled: {
          //   background: baseTheme.palette.primaryLight.main,
          //   color: baseTheme.palette.primary.main
          // }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            //background: "linear-gradient(98.24deg, rgba(35, 11, 49, 0.8) 7.89%, rgba(23, 12, 61, 0.8) 57.01%, rgba(23, 11, 49, 0.8) 101.27%), #FFFFFF",
            //boxShadow: "unset",
            //border: `1px solid ${baseTheme.palette.divider}`,
            borderRadius: BORDER_RADIUS,
            //color: 'white',
            //textAlign: 'center',
          }
        }
      },
      MuiContainer: {
        defaultProps: {
          //maxWidth: "lg",
          //fixed: true
        },
        styleOverrides: {
          root: {
            [baseTheme.breakpoints.up("md")]: {
              padding: "0 20px"
            },
            [baseTheme.breakpoints.up("lg")]: {
              padding: "0 70px"
            }

          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: baseTheme.palette.grey[200],
            '& .MuiTableCell-root': {
              fontWeight: 600,              
            },
            '@media print': {
              borderBottom: "1px solid " + baseTheme.palette.divider,              
              '& .MuiTableCell-root': {
                border: 0,
                background: 'white !important' 
              }
            }
          }
        }
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            border: 0,
            background: baseTheme.palette.secondaryLight.main,
            '& .MuiTableCell-root': {
              borderBottom: 0,
              color: baseTheme.palette.secondary.contrastText,            
              fontWeight: 600, 
            },
            '@media print': {
              borderTop: "1px solid " + baseTheme.palette.divider,
              '& .MuiTableCell-root': {
                border: 0,
                background: 'white !important' 
              }
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          sizeMedium: {
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: 400,
            padding: baseTheme.spacing(2),
            '&:first-child': {
              paddingLeft: baseTheme.spacing(2)
            },
            '&:last-child': {
              paddingRight: baseTheme.spacing(2)
            },
            '@media print': {
              '-webkit-print-color-adjust': 'exact !important',
              padding: baseTheme.spacing(.5),
              border: 0,
              backgroundColor: baseTheme.palette.grey[100]
            }
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            padding: "0 8px"
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            '.MuiGrid-item': {
              maxWidth: '100%'
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: BUTTON_RADIUS,
            paddingLeft: 22,
            paddingRight: 22,
            [baseTheme.breakpoints.down("sm")]: {
              //minWidth: 270
              width: "100%",
              minHeight: '47px'
            }
          },
          outlined: {
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: colors.primary[50]
            },
            '&.Mui-disabled': {
              backgroundColor: baseTheme.palette.background.default
            }
          }
        }
      }
    }
  });
}


export const buildClientTheme = (branding: ClientBranding) => {

  return buildTheme((defaultPalette: PaletteOptions) => {
    return  {
      ...defaultPalette,
      primary: {
        main: branding?.primary_color || "#103A49"
      },
      primaryLight: {
        dark: "#103A49",
        main: branding?.primary_color  || "#dbf3ff",
        light: "#ebf3ff"
      },
      secondary: branding?.secondary_color ? {
        dark: branding?.secondary_color,
        main: branding?.secondary_color,
        light: branding?.secondary_color
      }: defaultPalette.secondary,
      secondaryLight: branding?.secondary_color ? {
        main: branding?.secondary_color,
      } : defaultPalette.secondaryLight,
      
      appBarBackground: {
        main: branding?.primary_color  || "#103A49"
      }
    }
  })
}

export default buildTheme((defaultPalette)=>defaultPalette)